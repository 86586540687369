unit main;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs;

type
  TMainForm = class(TForm)
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  MainForm: TMainForm;

implementation

{$R *.dfm}

procedure TMainForm.LoadDFMValues;
begin


  try
    Name := 'MainForm';
    CSSLibrary := cssNone;
    Color := clWhite;
    Font.Charset := ANSI_CHARSET;
    Font.Color := clBlack;
    Font.Height := 0;
    Font.Name := 'Arial';
    Font.Size := 8;
    Font.Style := [];
    FormContainer := 'body';
    FormStyle := fsNormal;
    Height := 480;
    Left := 0;
    TabOrder := 0;
    Top := 0;
    Width := 640;
  finally
  end;
  inherited LoadDFMValues;
end;

end. 